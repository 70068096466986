<template>
  <main>
    <section class="flex flex-col h-full my-16 justify-items-center items-center justify-center">
      <img src="../assets/searching-box.png" alt="searching-box" />
      <h3 class="text-brand-black text-2xl font-cooper font-semibold text-center">{{ title }}</h3>
      <p class="text-brand-black text-center text-opacity-70 lg:px-64 mb-10">
        {{ text }}
      </p>
      <button v-if="button" @click="doAction" class="text-white bg-brand-button px-5 py-2 rounded-lg flex items-center">
        <slot></slot>
      </button>
    </section>
  </main>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
  title: { type: String },
  text: { type: String },
  button: { type: Boolean, default: false },
});

const emit = defineEmits(['doSomething']);
const doAction = () => {
  emit('doSomething');
};
</script>
