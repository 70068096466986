<template>
  <component
    :is="is"
    :class="componentClass"
    :href="href"
    :type="computedType"
    :to="to"
    :target="target"
    :disabled="disabled"
  >
    <component v-if="icon" :class="[size]" class="fill-current flex-none" :is="icon" />
    <span v-if="label" :class="labelClass">{{ label }}</span>
  </component>
</template>

<script>
import { getButtonColor } from "@/colors.js";

export default {
  props: {
    size: [String],
    label: {
      type: [String, Number],
      default: null,
    },
    icon: {
      type: [String, Object, Function],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "white",
    },
    as: {
      type: String,
      default: null,
    },
    small: Boolean,
    outline: Boolean,
    active: Boolean,
    disabled: Boolean,
  },

  computed: {
    is() {
      if (this.as) {
        return this.as;
      }

      if (this.to) {
        return "router-link";
      }

      if (this.href) {
        return "a";
      }

      return "button";
    },
    computedType() {
      if (this.is === "button") {
        return this.type ?? "button";
      }

      return null;
    },
    labelClass() {
      return this.small && this.icon ? "px-1" : "px-2";
    },
    componentClass() {
      const base = [
        "flex",
        "cursor-pointer",
        "justify-center",
        "items-center",
        "whitespace-nowrap",
        "focus:outline-none",
        "transition-colors",
        "focus:ring-0",
        "duration-150",
        this.active ? "ring ring-black dark:ring-white" : "focus:ring-0",
        this.small ? "p-2 rounded-full w-[8rem]" : "p-3 rounded-md",
        this.outline ? "border" : "border-0",
        getButtonColor(this.color, this.outline, !this.disabled),
      ];

      if (this.disabled) {
        base.push("cursor-not-allowed", this.outline ? "opacity-50" : "opacity-70");
      }

      return base;
    },
  },
};
</script>
