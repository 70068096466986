const sectionBgBase = 'bg-gradient-to-tr';
export const sectionBgLogin = `${sectionBgBase} from-purple-400 via-pink-500 to-red-500`;
export const sectionBgLoginDark = `${sectionBgBase} from-purple-900 via-pink-900 to-red-900`;
export const sectionBgError = `${sectionBgBase} from-pink-400 via-red-500 to-yellow-500`;
export const sectionBgErrorDark = `${sectionBgBase} from-pink-900 via-red-900 to-yellow-900`;

export const colorsBg = {
  white: 'bg-white text-[#090E1166]/70',
  light: 'bg-gray-100 text-gray-800',
  infoOpacity: 'bg-brand-original text-brand-original bg-opacity-10',
  primary: 'bg-brand-primary text-white',
  primaryLight: 'bg-[#FDE8F0] text-brand-primary',
  infoLight: 'bg-[#FDE8F0] text-brand-primary',
  danger: 'bg-red-600 text-white',
  dangerOpacity: 'bg-[#DD3E3E]/20 text-[#DD3E3E]',
  secondary: 'bg-brand-button text-white',
  info: 'bg-brand-original text-white',
  chat: 'bg-[#6D2E5D] text-white',
  transparent: 'bg-transparent border-0',
  success: 'bg-brand-green text-white',
  greyOpacity: 'bg-[#1B224B0D] text-[#1B224B]',
};

export const colorsBgHover = {
  white: 'hover:bg-gray-50',
  light: 'hover:bg-gray-200',
  success: 'hover:bg-brand-green',
  primary: 'hover:bg-brand-side',
  danger: 'hover:bg-red-700',
  secondary: 'hover:bg-brand-button',
  info: 'hover:bg-brand-original',
};

export const colorsBorders = {
  white: 'border-[#090E1166]/40',
  light: 'border-gray-200 dark:border-gray-400',
  success: 'border-brand-green',
  primary: 'border-brand-side',
  danger: 'border-red-700',
  secondary: 'border-brand-button',
  info: 'border-brand-original',
};

export const colorsText = {
  white: 'text-[#090E1166]/70',
  light: 'text-gray-700 dark:text-gray-400',
  success: 'text-emerald-600',
  primary: 'text-brand-side',
  danger: 'text-red-600',
  secondary: 'text-brand-button',
  info: 'text-brand-original',
};

export const colorsOutline = {
  white: [colorsText.white, colorsBorders.white],
  light: [colorsText.light, colorsBorders.light],
  success: [colorsText.success, colorsBorders.success],
  primary: [colorsText.primary, colorsBorders.primary],
  danger: [colorsText.danger, colorsBorders.danger],
  secondary: [colorsText.secondary, colorsBorders.secondary],
  info: [colorsText.info, colorsBorders.info],
};

export const colorsOutlineHover = {
  white: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-900',
  light: 'hover:bg-gray-100 hover:text-gray-900 dark:hover:text-gray-900',
  success: 'hover:bg-brand-green hover:text-white',
  primary: 'hover:bg-brand-side hover:text-white',
  danger: 'hover:bg-red-700 hover:text-white',
  secondary: 'hover:bg-brand-green hover:text-white hover:border-brand-green',
  info: 'hover:bg-brand-original hover:text-white',
};

export const getButtonColor = (color, isOutlined, hasHover) => {
  const base = [isOutlined ? colorsText[color] : colorsBg[color], colorsBorders[color]];

  if (hasHover) {
    base.push(isOutlined ? colorsOutlineHover[color] : colorsBgHover[color]);
  }

  return base;
};
