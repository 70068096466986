<template>
  <div class="flex flex-col h-full" :class="[overflow ? 'overflow-y-scroll z-50 top-0 h-full' : '']">
    <div class="mb-4 border-b border-gray-200" :class="[hidden ? 'hidden md:block' : 'md:block']">
      <ul
        class="flex items-center -mb-px text-sm font-medium text-center overflow-x-scroll whitespace-nowrap scrollbar-hide snap-proximity snap-center"
        :class="[!spread ? 'justify-between' : '']"
        :id="tabId"
        :data-tabs-toggle="dataTabsToggle"
        role="tablist"
      >
        <li v-for="(tab, i) in numberOfTabs" :key="i" class="">
          <button
            class="inline-block text-base p-4 rounded-t-lg"
            type="button"
            @click="toggleTabs(i + 1)"
            :class="[currentTab !== i + 1 ? 'text-brand-black text-opacity-60' : 'text-brand-black font-bold border-b', borderColor]"
          >
            {{ tab.name }}
          </button>
        </li>
      </ul>
    </div>
    <div :id="tabContentId" :class="className" class="grow overflow-y-auto scrollbar-hide">
      <slot></slot>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  aria: { type: String },
  tabId: { type: String, default: 'myTab' },
  tabContentId: { type: String, default: 'myTabContent' },
  className: { type: String },
  numberOfTabs: { type: Array },
  currentTab: Number,
  borderColor: String,
  hidden: Boolean,
  spread: Boolean,
  overflow: Boolean,
});
const emit = defineEmits(['changeTab']);
const dataTabsToggle = computed(() => `#${props.tabContentId}`);
const toggleTabs = (tabNumber) => {
  emit('changeTab', tabNumber);
};
</script>
