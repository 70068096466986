<template>
  <div class="w-full md:w-[calc(100vw-20rem)] bg-white py-2 fixed right-0 bottom-0 z-10">
    <section class="flex items-center justify-between mb-2">
      <!-- <div class="w-full relative flex items-center border border-purple-600"> -->
      <div class="px-3">
        <tam-button
          class="rounded-full focus:ring-0 p-6 border-0"
          color="chat"
          :icon="Cross"
        />
      </div>
      <!-- <div class="border border-brand-primary-dark rounded-lg w-full"> -->
      <textarea
        style="height: 50px"
        rows="3"
        v-model="textField"
        type="text"
        placeholder="Type a message"
        class="bg-light-background focus:ring-0 focus:outline-none text-brand-black w-full rounded-lg overflow-y-scroll scrollbar-hide"
      ></textarea>
      <!-- </div> -->
      <div class="px-3">
        <!-- <tam-button class="rounded-full mr-2 text-white" color="transparent" :icon="Emoji" /> -->
        <tam-button
          @click.stop="sendMessage"
          class="rounded-full p-6 text-white border-0 focus:ring-0"
          color="chat"
          :icon="Send"
        />
      </div>
      <!-- </div> -->
    </section>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed } from "vue";
import { Cross, Send, Emoji } from "@/icons";
import TamButton from "@/components/TamButton.vue";
import { useStore } from "vuex";

const emits = defineEmits(["newChat"]);
const props = defineProps(["conversationId", "receiver_identifier"]);
const store = useStore();

const textField = ref("");
const isSending = ref(false);

const sendMessage = async () => {
  if (!isSending.value && textField.value) {
    isSending.value = true;
    await store.dispatch("conversation/createConversation", {
      conversation: props.conversationId,
      receiver_identifier: props.receiver_identifier,
      type: "text",
      url: null,
      content: textField.value,
    });
    textField.value = "";
    isSending.value = false;
  }
};
</script>

<style>
textarea {
  resize: none;
}
</style>
